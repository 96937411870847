import { mapActions } from 'vuex'

export default {
  data: () => ({
    loading: false,
    snackbar: false,
    message: '',
    error: false,
    errors: [],
    selection: 1,
    token: null,
    loadingLogout: false,
    teacher: null,
    institution: null,
    showMark: false,
    showMaps: false,
    loadingMark: false,
    sheet: null,
    position: {
      lat: 0,
      lng: 0,
    },
    sheet: {
      id: 1,
      items: [ { date: '2022-08-01' } ]
    },
    times: [ 
      {field: 'hour_start1', label: 'Entrada Prevista', color: 'cyan'}, 
      {field: 'hour_end1', label: 'Saída Prevista', color: 'green'}, 
      // {field: 'hour_start2', color: 'pink'}, 
      // {field: 'hour_end1', color: 'amber'},
      // {field: 'hour_start3', color: 'pink'}, 
      // {field: 'hour_end3', color: 'amber'},
    ],
    
  }),
  computed: {
    
    source: function() {
      return 'https://www.google.com/maps/embed/v1/view?key=AIzaSyA7BinytdPhp-aN5QbC8rwGwTHgHhdA7Rk&center=' + this.position.lat + ',' + this.position.lng + '&zoom=18';
    },

    formatedDate: function() {
      return new Date().toLocaleDateString();
    },

  },
  created() {
    this.token = this.$route.query.token;
    this.getData();
    this.getLocation();
  },

  mounted() {

    
  },

  methods: {
    ...mapActions('timeSheet', ['ActionShowTimeSheet', 'ActionLogout', 'ActionStoreRegisterSheetItem']),

    getData(){
      
      this.loading = true;
      this.teacher = null;
      this.institution  = null;
      this.sheet        = null;

      this.ActionShowTimeSheet({ token: this.token })
          .then((res) => {
                this.teacher      = res.data.teacher;
                this.institution  = res.data.institution;
                this.sheet        = res.data.sheet;
          })
          .finally(() => {
              this.loading = false;
          })
  },

    logout(){
        
      this.loadingLogout = true;

      this.ActionLogout({ token: this.token })
          .then((res) => {
              this.$router.push({ path: '/access-time-sheet' });
          })
          .finally(() => {
            this.loadingLogout = false;
          });
  },
    
    mark(){
        
        this.loadingMark = true;
        this.error = false;
        this.message = '';

        var payload ={
          id:           (this.sheet && this.sheet.items && this.sheet.items.length > 0) ? this.sheet.items[0].id : 0,
          token:        this.token,
          localization: this.position.lat + ',' + this.position.lng,
          device:       navigator.userAgent,
        };

        this.ActionStoreRegisterSheetItem(payload)
            .then((res) => {
                this.message = res.message;
                this.getData();
            }).catch((error) => {
                this.error = true;
                this.message = error.message;
            })
            .finally(() => {
              this.loadingMark = false;
              this.showMark = false;
            });
    },

    getLocation()
    {
        
        if(!this.showMaps){
          return;
        }
      
        if (navigator.geolocation)
        {
                navigator.geolocation.getCurrentPosition((pos) => {
                  this.position.lat = pos.coords.latitude;
                  this.position.lng = pos.coords.longitude;
                });
        }
        else{
                alert("O seu navegador não suporta Geolocalização.");
        }
    },
        
  }
}